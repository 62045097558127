/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

import {
  Card,
  Col,
  Row,
  Typography,
  Button,
  Timeline,
} from "antd";
import {
  MenuUnfoldOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import Main from "../components/layout/Main";
import card from "../assets/images/info-card-1.jpg";

import {AiChat, useAsStreamAdapter} from '@nlux/react';
import { send } from '../chatbot/send';
import { personas } from '../chatbot/personas';
import '@nlux/themes/nova.css';

const { Title, Text } = Typography;
  
const Home: React.FC = () => {

  const adapter = useAsStreamAdapter(send, []);

  const [reverse, setReverse] = useState(false);

  const timelineList = [
    {
      title: "Complete Risk Assessment",
      time: "04 JUN 3:10 PM",
    },
    {
      title: "Add Employees",
      time: "09 JUN 7:20 PM",
      color: "green",
    },
    {
      title: "Assign Trainings",
      time: "08 JUN 12:20 PM",
      color: "green",
    },
    {
      title: "Publish Incident Notice",
      time: "02 JUN 2:45 PM",
    },
  ];

  return (
    <>
      <Main>
        <div className="layout-content">
          <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
              <Card bordered={false} className="criclebox cardbody h-full">
              <AiChat
                adapter={ adapter }
                personaOptions={ personas }
                composerOptions={{
                    placeholder: 'How can I help you today?'
                }}
                conversationOptions={{
                    historyPayloadSize: 'max'
                }}
              />
              </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <div className="timeline-box">
                  <Title level={5}>Get Started</Title>
                  <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                    <span className="bnb2">20%</span>
                  </Paragraph>

                  <Timeline
                    pending="Recording..."
                    className="timelinelist"
                    reverse={reverse}
                  >
                    {timelineList.map((t, index) => (
                      <Timeline.Item color={t.color} key={index}>
                        <Title level={5}>{t.title}</Title>
                        <Text>{t.time}</Text>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                  <Button
                    type="primary"
                    className="width-100"
                    onClick={() => setReverse(!reverse)}
                  >
                    {<MenuUnfoldOutlined />} REVERSE
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>

          <Row gutter={[24, 0]}>
            <Col xs={24} md={12} sm={24} lg={12} xl={14} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <Row gutter={[16, 16]}>
                  <Col
                    xs={24}
                    md={12}
                    sm={24}
                    lg={12}
                    xl={14}
                    className="mobile-24"
                  >
                    <div className="h-full col-content p-20">
                      <div className="card-footer">
                        <a className="icon-move-right" href="#pablo">
                          Read More
                          {<RightOutlined />}
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    md={12}
                    sm={24}
                    lg={12}
                    xl={10}
                    className="col-img"
                  >
                    <div className="ant-cret text-right">
                      <img src={card} alt="" className="border10" />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={24} md={12} sm={24} lg={12} xl={10} className="mb-24">
              <Card bordered={false} className="criclebox card-info-2 h-full">
                <div className="gradent h-full col-content">
                  <div className="card-content">
                    <Title level={5}>Work with the best</Title>
                    <p>
                      Wealth creation is an evolutionarily recent positive-sum
                      game. It is all about who take the opportunity first.
                    </p>
                  </div>
                  <div className="card-footer">
                    <a className="icon-move-right" href="#pablo">
                      Read More
                      <RightOutlined />
                    </a>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Main>
    </>
  );
}

export default Home;
