import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Modal, Row, message } from "antd";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Main from "../components/layout/Main";

const TrainingViewer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [mediaUrl, setMediaUrl] = useState<string | null>(null);
  const [mediaType, setMediaType] = useState<string | null>(null);
  const token = localStorage.getItem('jwtToken');
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    if (!token) {
      throw new Error('Token not found');
    }

    const fetchMediaType = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/training/record/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { trainingTemplate } = response.data;
        setMediaType(trainingTemplate.type);
        setMediaUrl(trainingTemplate.mediaURL);
      } catch (error) {
        message.error('Error fetching media type');
      }
    };

    fetchMediaType();
  }, [id, token]);

  const markTrainingComplete = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_DOMAIN}/training/record/${id}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      message.error('Error marking training as complete');
    }
  };

  const markTrainingInProgress = useCallback(async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_DOMAIN}/training/record/${id}/in-progress`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('Training marked as in progress');
    } catch (error) {
      message.error('Error marking training as in progress');
    }
  }, [id, token]);


  useEffect(() => {
    if (mediaType === 'video' && videoRef.current) {
      const videoElement = videoRef.current;
      const handleTimeUpdate = () => {
        const currentTime = videoElement.currentTime;
        const duration = videoElement.duration;

        if (currentTime / duration >= 0.01) {
          markTrainingInProgress();
          videoElement.removeEventListener('timeupdate', handleTimeUpdate);
        }
        
      };
      const handleVideoEnd = () => {
        setIsModalVisible(true);
      };

      videoElement.addEventListener('timeupdate', handleTimeUpdate);
      videoElement.addEventListener('ended', handleVideoEnd);
      return () => {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
        videoElement.removeEventListener('ended', handleVideoEnd);
      };
    }
  }, [mediaType, markTrainingInProgress]);

  const handleReplay = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
    setIsModalVisible(false);
  };

  const handleReturn = () => {
    navigate('/mytrainings');
  };

  const handleConfirm = () => {
    markTrainingComplete();
    setIsModalVisible(false);
    window.location.href = '/mytrainings';
  };

  const renderContent = () => {
    if (mediaType === 'video') {
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <video
            ref={videoRef}
            width="100%"
            height="100%"
            controls
            src={mediaUrl || ''}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      );
    } else if (mediaType === 'pdf') {
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <iframe
            width="100%"
            height="100%"
            src="https://example.com/sample.pdf" // Placeholder PDF URL
            frameBorder="0"
            title="PDF Viewer"
          ></iframe>
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  };

  return (
    <Main>
      <Row gutter={[24, 0]} style={{ height: '100vh', overflow: 'hidden' }}>
        {renderContent()}
      </Row>
      <Modal
        title="Video Completed"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="confirm" type="primary" onClick={handleConfirm}>
            Confirm
          </Button>,
          <Button key="replay" onClick={handleReplay}>
            Replay
          </Button>,
          <Button key="return" onClick={handleReturn}>
            Return to My Trainings
          </Button>,
        ]}
      >
        <p>I attest to watching the video in its entirety</p>
      </Modal>
    </Main>
  );
};

export default TrainingViewer;
